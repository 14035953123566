import React from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { getBanners2 /*, getHomeJSON*/ } from "common/services/apiService";
import { randomNumber } from "common/utils/util";
import { Link } from "react-router-dom";
import Adsense from "common/components/Adsense/Adsense";

import "./Banners.scss";

export default
@inject("accountStore")
@observer
class Banners extends React.Component {
  @observable banners = [];

  constructor(props) {
    super(props);
    this.state = { width: 230, height: 150 };
  }

  componentDidMount() {
    //json data for hard-coded stuff:
    /*const cache = randomNumber(100000, 1000000)
    getHomeJSON('Banners', 'banners', cache).then(res => {
      this.banners = res.banners
    })*/
    getBanners2().then((res) => {
      this.banners = res;
    });
  }

  render() {
    const { showAds } = this.props;
    const cache = randomNumber(100000, 1000000);
    return (
      <div style={{ paddingTop: "20px" }}>
        {this.banners.map((banner, index) =>
          banner.type == "html" ? (
            <HtmlBanner
              key={index}
              id={banner.id}
              url={banner.url}
              landingPage={banner.landingPage}
              width={banner.width}
              height={banner.height}
              cache={cache}
              customStyleName={index === 0 ? "img1" : ""}
            />
          ) : (
            <ImageBanner
              key={index}
              id={banner.id}
              url={banner.url}
              landingPage={banner.landingPage}
              width={banner.width}
              height={banner.height}
              cache={cache}
              props={this.props}
              customStyleName={index === 0 ? "img1" : ""}
            />
          )
        )}
        {showAds && (
          <div
            style={{
              position: "relative",
              marginTop: "2rem",
              paddingRight: "2rem",
              width: `${this.state.width}px`,
              height: `${this.state.height}px`,
            }}
          >
            <div
              style={{
                display: "block",
                position: "absolute",
                height: `${this.state.height}px`,
                width: `${this.state.width}px`,
              }}
            >
              <Adsense
                style={{
                  display: "block",
                  width:`${100}%`,
                  height:`${100}%`
                }}
                slot="7101597371"
                googleAdId="ca-pub-7408487462494237"
                format="banner"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const HtmlBanner = ({
  id,
  url,
  landingPage,
  width,
  height,
  cache,
  customStyleName,
}) => {
  return (
    <iframe
      id={`Banner${id}`}
      width={width}
      height={height}
      src={`${url}?cache=${cache}`}
      style={{ border: "none", marginBottom: "10px" }}
      styleName={customStyleName}
    ></iframe>
  );
};

const ImageBanner = ({
  id,
  url,
  landingPage,
  width,
  height,
  cache,
  props,
  customStyleName,
}) => {
  const { accountStore } = props;
  const installedProductID = accountStore.profile
    ? accountStore.profile.ID
    : -1;
  //console.log('landingPage',landingPage);
  return (
    <div
      id={`Banner${id}`}
      width={width}
      height={height}
      style={{ marginBottom: "10px" }}
      styleName="image-banner"
    >
      {landingPage && landingPage !== "" ? (
        <a
          href={`${landingPage.replace(
            /xINSTx/g,
            installedProductID
          )}&cache=${cache}`}
          target="_blank"
        >
          <img
            src={`${url}?cache=${cache}`}
            width={width}
            height={height}
            border={0}
            styleName={customStyleName}
          />
        </a>
      ) : (
        <img
          src={`${url}?cache=${cache}`}
          width={width}
          height={height}
          styleName={customStyleName}
        />
      )}
    </div>
  );
};

/*
<Link 
  to={`/redirector/${encodeURIComponent(landingPage)}/${encodeURIComponent(JSON.stringify({ bannerID: id }))}`} 
  //to={landingPage.replace(/xINSTx/g, installedProductID)}
  target="_blank">
  <img
    src={`${url}?cache=${cache}`}
    width={width}
    height={height}
  />
</Link>
*/
